@import url(https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre&display=swap);
.big-picture {
  /* position: fixed; */
  grid-column: 1;
  grid-row: 1;

  top: 0;
  left: 0;

  width: 100vw;
  height: auto;
  max-height: 100vh; 
  object-fit: cover;
  object-position: left top;
  transition: -webkit-clip-path 1000ms ease-in-out;
  transition: clip-path 1000ms ease-in-out;
  transition: clip-path 1000ms ease-in-out, -webkit-clip-path 1000ms ease-in-out;
  -webkit-clip-path: inset(0% 20% 0% 20%);
          clip-path: inset(0% 20% 0% 20%);
  z-index: -1;
}

.center-content {
  padding-top: 18%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.big-picture-text {
  color: white;
  padding: 10;
}

.animate-reveal {
  -webkit-clip-path: inset(0% 0% 0% 0%);
          clip-path: inset(0% 0% 0% 0%);
}

.lower-case {
  text-transform: none;
}

.body::first-letter {
  font-size: 56px;
  line-height: 48px;
  float: left;
  padding-right: .05em;
  padding-top: 0px;
  margin-bottom: -10px;
}

html {
  visibility: visible;
  opacity: 1;
}
